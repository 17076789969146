<template>
  <div class="country-select">
    <v-tabs fixed-tabs>
      <v-tab>{{ $t("MARKETS.TITLE1") }}</v-tab>
      <v-tab>{{ $t("MARKETS.TITLE2") }}</v-tab>
      <v-tab>{{ $t("MARKETS.TITLE3") }}</v-tab>
      <v-tab>{{ $t("MARKETS.TITLE4") }}</v-tab>
      <v-tab>{{ $t("MARKETS.TITLE5") }}</v-tab>
      <v-tab>{{ $t("MARKETS.TITLE6") }}</v-tab>

      <!-- AFRICA -->
      <v-tab-item class="pt-5">
        <ProductsTreeView
          :input.sync="form.africa"
          :selection="form.africa"
          :data="Continents.AFRICA"
          name=""
          key-id="countryId"
          key-name="nameTranslation"
        />
      </v-tab-item>

      <!-- ASIA -->
      <v-tab-item class="pt-5">
        <ProductsTreeView
          :input.sync="form.asia"
          :selection="form.asia"
          :data="Continents.ASIA"
          name=""
          key-id="countryId"
          key-name="nameTranslation"
        />
      </v-tab-item>

      <!-- europe -->
      <v-tab-item class="pt-5">
        <ProductsTreeView
          :input.sync="form.europe"
          :selection="form.europe"
          :data="Continents.EUROPE"
          name=""
          key-id="countryId"
          key-name="nameTranslation"
        />
      </v-tab-item>

      <!-- north_america -->
      <v-tab-item class="pt-5">
        <ProductsTreeView
          :input.sync="form.north_america"
          :selection="form.north_america"
          :data="Continents.N_AMER"
          name=""
          key-id="countryId"
          key-name="nameTranslation"
        />
      </v-tab-item>

      <!-- oceania -->
      <v-tab-item class="pt-5">
        <ProductsTreeView
          :input.sync="form.oceania"
          :selection="form.oceania"
          :data="Continents.OCEAN"
          name=""
          key-id="countryId"
          key-name="nameTranslation"
        />
      </v-tab-item>

      <!-- south_america -->
      <v-tab-item class="pt-5">
        <ProductsTreeView
          :input.sync="form.south_america"
          :selection="form.south_america"
          :data="Continents.S_AMER"
          name=""
          key-id="countryId"
          key-name="nameTranslation"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
export default {
  components: {
    ProductsTreeView,
  },
  data() {
    return {
      form: {
        africa: [],
        asia: [],
        europe: [],
        north_america: [],
        oceania: [],
        south_america: [],
      },
    };
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.loadFromData();
  },
  methods: {
    loadFromData() {
      if (this.data !== null) {
        if (this.data.AFRICA) {
          this.data.AFRICA.forEach(element => {
            this.form.africa.push({
              id: element.countryId,
              name: element.nameTranslation,
            });
          });
        }
        if (this.data.ASIA) {
          this.data.ASIA.forEach(element => {
            this.form.asia.push({
              id: element.countryId,
              name: element.nameTranslation,
            });
          });
        }
        if (this.data.EUROPE) {
          this.data.EUROPE.forEach(element => {
            this.form.europe.push({
              id: element.countryId,
              name: element.nameTranslation,
            });
          });
        }
        if (this.data.N_AMER) {
          this.data.N_AMER.forEach(element => {
            this.form.north_america.push({
              id: element.countryId,
              name: element.nameTranslation,
            });
          });
        }
        if (this.data.OCEAN) {
          this.data.OCEAN.forEach(element => {
            this.form.oceania.push({
              id: element.countryId,
              name: element.nameTranslation,
            });
          });
        }
        if (this.data.S_AMER) {
          this.data.S_AMER.forEach(element => {
            this.form.south_america.push({
              id: element.countryId,
              name: element.nameTranslation,
            });
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters(["Countries", "Continents"]),
  },
  watch: {
    form: {
      handler: function() {
        this.$emit("update:form-data", this.form);
      },
      deep: true,
    },
    data: {
      handler: function() {
        this.loadFromData();
      },
      deep: true,
    },
  },
};
</script>

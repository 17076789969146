<template>
  <div>
    <!-- BUTTONS -->
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="createNewMarket"
      :save_loading="loading_save"
    />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card class="mb-2">
        <v-card-text>
          <v-row>
            <!-- TITLE -->
            <b-col lg="12" md="12" sm="12" cols="12" class="mb-5">
              <h5>
                <strong>
                  {{ $t("MARKETS.TITLE8") }}
                </strong>
                <br />
                <i>
                  {{ $t("MARKETS.TITLE9") }}
                </i>
              </h5>
            </b-col>
            <!-- NAME -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                :label="$t('MARKETS.NAME')"
                :rules="validations.required"
                required
                outlined
                dense
              />
            </v-col>
            <!-- TABS -->
            <v-col cols="12" md="12">
              <CountrySelect
                :form-data.sync="formData"
                :class="{ 'border--red-solid': customClass }"
              />
              <span class="ml-3 text-danger" v-if="customClass">
                {{ $t("GENERAL.FIELD_ERROR") }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>
<script>
import CountrySelect from "@/view/content/components/CountrySelect";
import { STORE_MARKET } from "@/core/services/store/market/market.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
import Buttons from "@/view/content/components/Buttons";
export default {
  components: {
    CountrySelect,
    Buttons,
  },
  data() {
    return {
      name: null,
      formData: null,
      loading_save: false,
      required: false,
      //Validations
      validations: {
        valid: true,
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * return campus page
     * @method cancel
     */
    cancel() {
      this.$router.push("/configure/markets");
    },

    /**
     * Create new Market
     * @method createNewMarket
     * @returns 'createNewMarket' object whit data
     */
    createNewMarket() {
      this.required = this.formData ? false : true;
      if (!this.$refs.form.validate() || this.required) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.loading_save = true;

        // GET COUNTRIES IDS
        const id_countries = [];
        const aux_conuntries = [
          "africa",
          "asia",
          "europe",
          "north_america",
          "oceania",
          "south_america",
        ];

        aux_conuntries.forEach(continent => {
          this.formData[continent].forEach(item => {
            id_countries.push(item.id);
          });
        });

        const dataNew = {
          name: this.name,
          campusId: this.getCampus.campusId,
          countries: id_countries.join(),
        };

        this.$store.dispatch(STORE_MARKET, dataNew).then(() => {
          this.loading_save = false;

          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });

          setTimeout(() => {
            this.$router.push("/configure/markets");
          }, 2000);
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MARKETS.TITLE"),
        route: "/configure/markets",
      },
      { title: this.$t("GENERAL.NEW") },
    ]);
  },
  computed: {
    ...mapGetters(["getCampus"]),
    customClass() {
      return this.required;
    },
  },
};
</script>

<style scoped>
.border--red-solid {
  border: 1px solid red;
}
</style>

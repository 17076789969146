import i18n from "@/core/plugins/vue-i18n";
const { GENERAL } = i18n.messages.en;
export default {
  /**
   * Field Required
   * @returns 'Boolean'
   */
  required: [v => !!v || GENERAL.FIELD_ERROR],

  /**
   * validate that the number entered min
   * @params v, type
   * @returns 'Boolean'
   */
  requiredCoupons: (v, editPage) => {
    if ((v === null || v === "") && !editPage) {
      return GENERAL.FIELD_ERROR;
    }
    return true;
  },

  /**
   * validate that the number entered min
   * @params v, type
   * @returns 'Boolean'
   */
  search: (v, type, editPage) => {
    if ((v === null || v === "") && !editPage) {
      return GENERAL.FIELD_ERROR;
    } else {
      const SEARCH_BY_ID = "1";
      if (type === SEARCH_BY_ID) {
        if (isNaN(v)) {
          return GENERAL.NUMBER_FIELDS;
        }
      } else {
        if (v?.length < 4) {
          return GENERAL.MIN_CHARACTERS;
        }
      }
    }
    return true;
  },

  /**
   * field required, integer and min 0
   * @returns 'Boolean'
   */
  minAge: [
    v => {
      if (Number(v) < 0) {
        return GENERAL.NEGATIVE_FIELDS;
      }
      if (!Number.isInteger(Number(v))) {
        return GENERAL.DECIMAL_FIELDS;
      }
      if (v === null || v === "") {
        return GENERAL.FIELD_ERROR;
      }
      return true;
    },
  ],

  /**
   * required and min 0
   * @returns 'Boolean'
   */
  requiredNoNegative: [
    v => {
      if (v === null || v === "") {
        return GENERAL.FIELD_ERROR;
      }
      if (Number(v) < 0) {
        return GENERAL.NEGATIVE_FIELDS;
      }
      return true;
    },
  ],

  /**
   * min 0
   * @returns 'Boolean'
   */
  noNegative: [
    v => {
      if (v !== null && v !== "") {
        if (Number(v) < 0) {
          return GENERAL.NEGATIVE_FIELDS;
        }
      }
      return true;
    },
  ],

  /**
   * field integer
   * @returns 'Boolean'
   */
  integer: [
    v => {
      if (v !== null && v !== "") {
        if (Number(v) < 0) {
          return GENERAL.NEGATIVE_FIELDS;
        }
        if (!Number.isInteger(Number(v))) {
          return GENERAL.DECIMAL_FIELDS;
        }
        return true;
      }
      return true;
    },
  ],

  /**
   * validate that the number entered is greater, integer and min 0
   * @params max, min
   * @returns 'Boolean'
   */
  biggerNumber: (min, max) => {
    if (max !== null && max !== "") {
      if (Number(max) < 0) {
        return GENERAL.NEGATIVE_FIELDS;
      }
      if (!Number.isInteger(Number(max))) {
        return GENERAL.DECIMAL_FIELDS;
      }
      if (min !== null && min !== "") {
        if (Number(max) <= Number(min)) {
          return GENERAL.NUMBER_GREATER;
        }
      }
    }
    return true;
  },

  /**
   * validate that the number entered is greater, integer and min 0
   * @params max, min
   * @returns 'Boolean'
   */
  biggerNumberRequired: (min, max, decimal = true) => {
    if (max === null || max === "") {
      return GENERAL.FIELD_ERROR;
    } else {
      if (Number(max) < 0) {
        return GENERAL.NEGATIVE_FIELDS;
      }
      if (!Number.isInteger(Number(max)) && decimal) {
        return GENERAL.DECIMAL_FIELDS;
      }
      if (min !== null && min !== "") {
        if (Number(max) <= Number(min)) {
          return GENERAL.NUMBER_GREATER;
        }
      }
    }
    return true;
  },

  /**
   * validate that the number entered is minor, integer and min 0
   * @params min, max
   * @returns 'Boolean'
   */
  minorNumber: (min, max, decimal = true) => {
    if (min !== null && min !== "") {
      if (Number(min) < 0) {
        return GENERAL.NEGATIVE_FIELDS;
      }
      if (!Number.isInteger(Number(min)) && decimal) {
        return GENERAL.DECIMAL_FIELDS;
      }
      if (max !== null && max !== "") {
        if (Number(max) <= Number(min)) {
          return GENERAL.NUMBER_MINOR;
        }
      }
    }
    return true;
  },

  /**
   * validate that the number entered is greater and min 0
   * @params max, min
   * @returns 'Boolean'
   */
  biggerNumber2: (min, max) => {
    if (max !== null && max !== "") {
      if (Number(max) < 0) {
        return GENERAL.NEGATIVE_FIELDS;
      }
      if (min !== null && min !== "") {
        if (Number(max) <= Number(min)) {
          return GENERAL.NUMBER_GREATER;
        }
      }
    }
    return true;
  },

  /**
   * validate max number of characters
   * @params value, maxvalue
   * @returns 'Boolean'
   */
  maxValue: (value, maxvalue) => {
    if (value !== null && value !== "" && value !== undefined) {
      if (value.length > Number(maxvalue)) {
        return i18n.t("VALIDATION.EXCEED_CHARS_MAXIMUM");
      }
    }
    return true;
  },

  /**
   * validate max number of characters
   * @params value, maxvalue
   * @returns 'Boolean'
   */
  date: [
    v => {
      if (v !== null || v !== "") {
        return true;
      }
      return true;
    },
  ],

  /**
   * validate greater than
   * @params value, minvalue
   * @returns 'Boolean'
   */
  greaterthan: (value, minvalue) => {
    if (value === null || typeof value === "undefined") return "Field required";
    if (parseInt(value) > parseInt(minvalue)) {
      return true;
    }
    return i18n.t("VALIDATION.GREATER_THAN", { minvalue: minvalue });
  },

  /**
   * Email
   * @returns 'Boolean'
   */
  email: [
    v => {
      if (v !== null) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(v)) {
          return i18n.t("VALIDATION.INVALID_EMAIL");
        }
      }
      return true;
    },
  ],

  /**
   * emailRequired
   * @returns 'Boolean'
   */
  emailRequired: [
    v => {
      if (v === null || v === "") {
        return GENERAL.FIELD_ERROR;
      } else {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(v)) {
          return i18n.t("VALIDATION.INVALID_EMAIL");
        }
      }
      return true;
    },
  ],

  /**
   * validate max number of characters and field Required
   * @params value, maxvalue
   * @returns 'Boolean'
   */
  maxValueRequired: (value, maxvalue) => {
    if (value === null || value === "") {
      return GENERAL.FIELD_ERROR;
    }
    if (value !== null) {
      if (value.length > Number(maxvalue)) {
        return i18n.t("VALIDATION.EXCEED_CHARS_MAXIMUM");
      }
    }
    return true;
  },

  address: (address, googlePlace, edit = false, oldData = false) => {
    if (address === null || address === "") {
      return GENERAL.FIELD_ERROR;
    }

    if (googlePlace !== null && googlePlace?.formatted_address !== address) {
      return GENERAL.ADDRESS_ERROR;
    }

    if (edit && address !== oldData && !googlePlace) {
      return GENERAL.ADDRESS_ERROR;
    }

    if (address !== "" && googlePlace === null && !edit) {
      return GENERAL.ADDRESS_ERROR;
    }

    return true;
  },

  addressWizard: (address, googlePlace) => {
    if (address === null || address === "") {
      return GENERAL.FIELD_ERROR;
    }

    if (googlePlace) {
      if (googlePlace?.formatted_address !== address) {
        return GENERAL.ADDRESS_ERROR;
      }
    }

    return true;
  },

  city: (city, googlePlace, edit = false, oldData = false) => {
    if (city === null || city === "") {
      return GENERAL.FIELD_ERROR;
    }

    if (googlePlace !== null && googlePlace?.formatted_address !== city) {
      return GENERAL.CITY_ERROR;
    }

    if (edit && city !== oldData?.translation && !googlePlace) {
      return GENERAL.CITY_ERROR;
    }

    if (city !== "" && googlePlace === null && !edit) {
      return GENERAL.CITY_ERROR;
    }

    return true;
  },

  /**
   * validate that the value entered is number
   * @params v, required
   * @returns 'Boolean'
   */
  policyNumber: (v, required) => {
    if (required) {
      if (v === null || v === "") {
        return GENERAL.FIELD_ERROR;
      }
      if (Number(v) < 0) {
        return GENERAL.NEGATIVE_FIELDS;
      }
      if (!Number.isInteger(Number(v))) {
        return GENERAL.DECIMAL_FIELDS;
      }
    }
    return true;
  },

  /**
   * validate that the value required
   * @params v, required
   * @returns 'Boolean'
   */
  policyText: (v, required) => {
    if (required) {
      if (v === null || v === "") {
        return GENERAL.FIELD_ERROR;
      }
    }
    return true;
  },

  /**
   * validate that the value required
   * @params v, required
   * @returns 'Boolean'
   */
  policyPercentage: (v, required) => {
    if (required) {
      if (v === null || v === "") {
        return GENERAL.FIELD_ERROR;
      }
      if (v < 0 || v > 100) {
        return GENERAL.PERCENTAGE;
      }
    }
    return true;
  },
};
